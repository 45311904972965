<template>
    <div>
        <cHeaderBuyer></cHeaderBuyer>
        <div class = "body">
            <div class="page-wrap">
                <div class="alain-pro_page-grid">
                    <router-view></router-view>
                </div>
            </div>
        </div>
        <cFooter></cFooter>
    </div>
</template>

<script>

    import cHeaderBuyer from '../component/header-buyer';
    import cFooter from '../component/footer';
    export default {
        name: "buyer-detail",
        components: {
            cHeaderBuyer,
            cFooter,
        },
        data(){
            return {

                userInfo: JSON.parse(localStorage.getItem('userInfo')),
            }
        },
    }
</script>

<style lang="scss" scoped>

    .page-wrap{
        width: 1200px;
        margin: auto;
    }

    .wrap {
        max-width: 1200px;
        margin: 0 auto;
        background-color: #fff;
    }
    .logo {
        width: 240px;
        height: 60px;
        margin-left: 2rem;
    }
</style>